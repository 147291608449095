<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">Enrollment Approval Detail</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <place-holder v-if="loading"></place-holder>
    <div class="row" v-show="!loading">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 custom-border">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Type</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.employee_type.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Group</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.group.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th style="vertical-align: top !important">
                                <span class="ms-1">Trainer</span>
                              </th>
                              <td>
                                <!-- style="display: block" -->
                                <span
                                  class="ms-1"
                                  v-for="(trainer, index) in trainers"
                                  :key="index"
                                  >{{ trainer.name }}
                                  <span v-if="index + 1 < trainers.length"
                                    >,
                                  </span>
                                </span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">From Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.from_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">To Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.to_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Enrolled Expired Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.expired_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Training Hour</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.module.duration
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Location</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.location
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                    <div class="col-xl-6">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody v-if="!loading">
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Name</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Id</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.emp_id
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Position Level</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.position_level?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Department</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.department?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">BU/BR/DIV</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_user?.business_unit?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Approved By</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Approved By Email</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by?.email
                                }}</span>
                              </td>
                            </tr>

                            <tr
                              class="custom-tr"
                              v-if="enroll_detail?.approved_by_hr?.name"
                            >
                              <th>
                                <span class="ms-1">Approved By HR</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by_hr?.name
                                }}</span>
                              </td>
                            </tr>
                            <tr
                              class="custom-tr"
                              v-if="enroll_detail?.approved_by_hr?.email"
                            >
                              <th>
                                <span class="ms-1">Approved By HR Email</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  enroll_detail?.approved_by_hr?.email
                                }}</span>
                              </td>
                            </tr>

                            <tr class="custom-tr">
                              <th class="status-top">
                                <span class="ms-1">Status</span>
                              </th>
                              <td>
                                <!-- height: 85px; -->
                                <div style="width: 180px; padding-left: 6px">
                                  <v-select
                                    v-if="
                                      enroll_confirmed_status == 'Rejected' ||
                                      enroll_confirmed_status == 'Approved' ||
                                      enroll_confirmed_status == 'Canceled' ||
                                      monthly_plan.expired_date <
                                        current_year_month_day
                                    "
                                    id="vue-selectCss"
                                    v-model="enroll_confirmed_status"
                                    label="name"
                                    :options="approved_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                    disabled
                                    data-bs-toggle="tooltip"
                                    :title="
                                      enroll_confirmed_status == 'Rejected' ||
                                      enroll_confirmed_status == 'Approved' ||
                                      enroll_confirmed_status == 'Canceled'
                                        ? 'You can not change status after approved or rejected or enrolled user canceled!'
                                        : 'You can not change status after registration period!'
                                    "
                                  >
                                  </v-select>
                                  <v-select
                                    v-else
                                    id="vue-selectCss"
                                    v-model="enroll_confirmed_status"
                                    label="name"
                                    :options="approved_status"
                                    :reduce="(name) => name.id"
                                    :clearable="false"
                                  >
                                  </v-select>
                                </div>
                              </td>
                            </tr>
                            <tr
                              v-if="
                                enroll_confirmed_status == 'Rejected' ||
                                enroll_confirmed_status == 'Canceled' ||
                                enroll_confirmed_status == 0
                              "
                              class="custom-tr"
                            >
                              <th>
                                <span class="ms-1">Remark</span>
                              </th>
                              <td>
                                <textarea
                                  v-model="enroll_detail.remark"
                                  v-if="
                                    enroll_confirmed_status == 'Rejected' ||
                                    enroll_confirmed_status == 'Canceled' ||
                                    monthly_plan.expired_date <
                                      current_year_month_day
                                  "
                                  disabled
                                  data-bs-toggle="tooltip"
                                  :title="
                                    enroll_confirmed_status == 'Rejected' ||
                                    enroll_confirmed_status == 'Canceled'
                                      ? 'You can not change status after rejected or  enrolled user canceled!'
                                      : 'You can not change remark after registration period!'
                                  "
                                  type="text"
                                  id="enroll_remark"
                                  name="enroll_remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  style="
                                    width: 174px;
                                    margin-left: 6px;
                                    cursor: not-allowed;
                                  "
                                />
                                <textarea
                                  v-else
                                  v-model="enroll_detail.remark"
                                  type="text"
                                  id="enroll_remark"
                                  name="enroll_remark"
                                  class="form-control"
                                  placeholder="Please add remark"
                                  style="width: 174px; margin-left: 6px"
                                  :class="{
                                    'p-invalid':
                                      v$.enroll_detail.remark.$error ||
                                      errorFor('enroll_remark'),
                                  }"
                                />
                                <v-errors
                                  id="custom-CSS"
                                  :serverErrors="errorFor('enroll_remark')"
                                  :vuelidateErrors="{
                                    errors: v$.enroll_detail.remark.$errors,
                                    value: 'Remark',
                                  }"
                                ></v-errors>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                  </div>
                  <div class="row" v-if="!loading">
                    <div class="col-md-12">
                      <div class="text-center mt-3 mb-3">
                        <router-link :to="{ name: 'enrollment-approval' }">
                          <button
                            type="button"
                            class="btn w-sm btn-secondary me-5"
                          >
                            Cancel
                          </button>
                        </router-link>
                        <button
                          v-if="
                            enroll_confirmed_status == 'Canceled' ||
                            monthly_plan.expired_date < current_year_month_day
                          "
                          type="button"
                          class="btn w-sm btn-success waves-effect waves-light px-3"
                          style="cursor: not-allowed"
                          data-bs-toggle="tooltip"
                          :title="
                            enroll_confirmed_status == 'Canceled'
                              ? 'You cannot change status after enrolled user canceled!'
                              : 'You cannot change status after registration period!'
                          "
                        >
                          Save
                        </button>
                        <button
                          v-else
                          :disabled="isLoading ? true : false"
                          type="button"
                          class="btn w-sm btn-success waves-effect waves-light px-3"
                          @click="updateMonthlyEnroll()"
                        >
                          <span
                            v-if="isLoading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {{ isLoading == true ? "Loading..." : "Save" }}
                        </button>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      current_year_month_day: "",
      monthly_plan: null,
      trainers: "",

      enroll_detail: "",
      enroll_status: "",
      enroll_confirmed_status: "",
      approved_status: [
        {
          id: 0,
          name: "Rejected",
        },
        {
          id: 1,
          name: "Approved",
        },
      ],
      enroll_user: null,

      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  validations() {
    return {
      enroll_detail: {
        remark: { required },
      },
    };
  },
  methods: {
    async getEnrollDetail() {
      let enrolled_user_id = null;
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/enroll-lists-detail/${this.$route.params.id}`,
          {
            headers: {
              "content-type": "text/plain",
            },
          }
        )
        .then((response) => {
          this.monthly_plan = response.data.data.monthly_plan;

          this.trainers = this.monthly_plan.trainers;

          this.enroll_detail = response.data.data;
          this.enroll_status = this.enroll_detail.is_enroll_confirmed;
          if (this.enroll_status == 0) {
            this.enroll_confirmed_status = "Rejected";
          } else if (this.enroll_status == 1) {
            this.enroll_confirmed_status = "Approved";
          } else if (this.enroll_status == 2) {
            this.enroll_confirmed_status = "Waiting";
          } else if (this.enroll_status == 3) {
            this.enroll_confirmed_status = "Canceled";
          }

          enrolled_user_id = this.enroll_detail.enrolled_by.employee_id;
        })
        .catch(() => {
          this.toast.error("Not Found Employees!");
        });
      await axios
        .get(`${this.baseUrl}admin/v1/employee/search/${enrolled_user_id}`)
        .then((response) => {
          this.enroll_user = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.loading = false;
    },

    async updateMonthlyEnroll() {
      if (this.enroll_confirmed_status == "Rejected") {
        this.enroll_confirmed_status = 0;
      } else if (this.enroll_confirmed_status == "Approved") {
        this.enroll_confirmed_status = 1;
      } else if (this.enroll_confirmed_status == "Waiting") {
        this.enroll_confirmed_status = 2;
      } else if (this.enroll_confirmed_status == "Canceled") {
        this.enroll_confirmed_status = 3;
      }

      let data = {
        is_enroll_confirmed: this.enroll_confirmed_status,
        enroll_remark: this.enroll_detail.remark,
        changed_status_by: this.$store.state.auth.user.employee_id,
      };

      if (
        this.enroll_confirmed_status == "Rejected" ||
        this.enroll_confirmed_status == 0
      ) {
        const isFormCorrect = await this.v$.$validate();
        if (!isFormCorrect) return;
      }

      this.isLoading = true;

      await axios
        .post(
          `${this.baseUrl}admin/v1/enroll-lists/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.toast.success("Successfully Enroll Status Updated");
          this.$router.push({ name: "enrollment-approval" });
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error(this.errors.data.message);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getEnrollDetail();
    this.current_year_month_day = moment().format("YYYY-MM-DD");
  },
  components: {
    vSelect,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
#vue-selectCss ul {
  position: relative !important;
}

tr:last-child #lastChildCSS ul {
  position: relative !important;
}

#custom-CSS p.mb-0 {
  margin-left: 6px;
  margin-top: 6px;
}
</style>

<style scoped>
.custom-tr {
  border-style: hidden !important;
}

.status-top {
  vertical-align: inherit !important;
}

.card {
  border: 1px solid #ccc !important;
  margin-bottom: 0px !important;
}

.table > :not(caption) > * > * {
  padding: 0.8rem 0.85rem;
}

.custom-border {
  border-right: solid #ccc !important;
  border-width: thin;
  height: auto;
}
</style>
